import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const Tester = () => {
  const location = useLocation();
  const [editedPage, setfirst] = useState(location.state.editedPage);
  const [addCss, setAddCss] = useState(location.state.addCss);
  return (
    <div style={{ margin: "40px" }}>
      <style dangerouslySetInnerHTML={{ __html: addCss }} />
      <div dangerouslySetInnerHTML={{ __html: editedPage }}></div>
    </div>
  );
};

export default Tester;
