import React from "react";
import "./styles/main.scss";
import grapesjs from "grapesjs";
import basicPlugin from "grapesjs-blocks-basic";

import gjspresetwebpage from "grapesjs-preset-webpage";
import "grapesjs-preset-webpage";
import grapesjsblocksbasic from "grapesjs-preset-newsletter";
import Editor from "./components/Editor.jsx";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Testers from "./components/Tester.jsx";
import Button from "./components/Button";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Editor />} />
        <Route exact path="/testing-ui" element={<Testers />} />
      </Routes>
      <Button
        onClick={() => {
          alert("clicked Button 1");
        }}
        text={"Button1"}
      />
    </BrowserRouter>
  );
};

export default App;
