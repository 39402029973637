import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import grapesjs from "grapesjs";
import basicPlugin from "grapesjs-blocks-basic";
import gjspresetwebpage from "grapesjs-preset-webpage";
import "grapesjs-preset-webpage";
import grapesjsblocksbasic from "grapesjs-preset-newsletter";
import Layout from "./Layout";

const Editor = () => {
  const [editor, setEditor] = useState(null);
  const [editedPage, setEditedPage] = useState(undefined);
  const [openPage, setOpenPage] = useState(false);
  const [addCss, setAddCss] = useState(undefined);

  useEffect(() => {
    const edit = grapesjs.init({
      container: "#editor",
      plugins: [
        "grapesjs-preset-webpage",
        basicPlugin,
        gjspresetwebpage,
        grapesjsblocksbasic,
        "your-plugin",
      ],

      components: [
        {
          id: "my-button",
          label: "My Button",
          content: '<button class="my-button">Click to continue...</button>',
        },
      ],
    });

    setEditor(edit);
  }, []);
  const exportCode = () => {
    const htmlCode = editor.getHtml();
    const cssCode = editor.getCss();
    const jsCode = editor.getJs();
    const modifiedHtmlCode = htmlCode.replace("body", "div");
    const modifiedHtmlCodefi = modifiedHtmlCode.replace("/body", "/div");
    let remove = modifiedHtmlCodefi.replace(/\n/, "");
    setEditedPage(remove);
    setOpenPage(true);
    setAddCss(cssCode);
    console.log(jsCode);

    return remove;
  };
  const handleView = () => {
    alert("hiii");
    return;
  };
  return (
    <Layout>
      <div id="App" style={{ display: "flex", flexDirection: "column" }}>
        {openPage && editedPage !== undefined && (
          <Navigate to="testing-ui" state={{ editedPage, addCss }} />
        )}
        <div id="editor"></div>
        <div className="publish-btn-container">
          <button
            className="publish-btn"
            onClick={() => {
              exportCode();
            }}
          >
            submit and publish
          </button>
        </div>
        {/* <Testing editedPage={editedPage} openPage={openPage} /> */}
      </div>
    </Layout>
  );
};

export default Editor;
